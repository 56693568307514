<template>
  <v-container class="p-3 mt-5">
    <section class="d-flex flex-column flex-sm-row justify-content-between">
      <h2 class="ml-1 my-2">
        Solicitudes de Traslado
      </h2>
      <v-breadcrumbs :items="breadcrumb"></v-breadcrumbs>
    </section>
    <v-row align-content="center" align-md="center" class="mt-1 mb-5">
      <v-col cols="12" md="3" sm="12" class="pb-0">
        <filter-project
          label="Proyecto"
          :projects="
            projects.items
              ? projects.items.filter((item) => item.warehouse)
              : []
          "
          :getProjectsFunction="getProjects"
          @cleanProject="cleanCurrentProject"
          @inputProject="
            updateCurrentProject($event);
            getRequestsDeliveryData(filters.page);
          "
        ></filter-project>
      </v-col>

      <v-col cols="12" md="3" sm="12" class="pb-0">
        <v-menu
          ref="menu"
          v-model="datepicker_menu_start"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date_start_formatted"
              prepend-icon="mdi-calendar"
              readonly
              label="Fecha despacho (desde)"
              v-bind="attrs"
              v-on="on"
              clearable
              @click:clear="clearDateFilters"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="filters.date_start"
            scrollable
            locale="es"
            :max="maxDateStart"
            @input="
              datepicker_menu_start = false;
              getRequestsDeliveryDataAfterValidateDates();
            "
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="3" sm="12" class="pb-0">
        <v-menu
          ref="menu"
          v-model="datepicker_menu_end"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date_end_formatted"
              prepend-icon="mdi-calendar"
              readonly
              label="Fecha despacho (hasta)"
              v-bind="attrs"
              v-on="on"
              clearable
              @click:clear="clearDateFilters"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="filters.date_end"
            scrollable
            locale="es"
            clearable
            :min="minDateEnd"
            @input="
              datepicker_menu_end = false;
              getRequestsDeliveryDataAfterValidateDates();
            "
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="3" sm="12" class="pb-0">
        <v-select
          v-model="filters.received_type"
          item-text="name"
          item-value="value"
          label="Tipo de Despacho"
          :items="TYPE_REQUESTS_DISPATCH"
          clearable
          @input="getRequestsDeliveryData(1)"
        ></v-select>
      </v-col>
      <v-col cols="12" md="4" sm="12" class="pb-0">
        <v-autocomplete
          v-model="filters.warehouse"
          clearable
          label="Filtrar por bodega"
          :items="warehouses.items ? warehouses.items : []"
          @input="getRequestsDeliveryData(1)"
          item-value="code"
          item-text="name"
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="4" sm="12" class="pb-0">
        <v-autocomplete
          v-model="filters.dispatch_status"
          clearable
          label="Filtrar por despacho"
          :items="MAP_STATUS_DISPATCH_TRANSFER_REQUEST"
          @input="getRequestsDeliveryData(1)"
          item-value="id"
          item-text="name"
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="4" sm="12" class="pb-0 mt-5">
        <v-checkbox
          @click="getRequestsDeliveryData(1)"
          v-model="filters.date_dispatch"
        >
          <template v-slot:label>
            <h6 class="mt-2 mx-1 mb-0">
              Solicitudes sin Fecha de Entrega
            </h6>
          </template>
        </v-checkbox>
      </v-col>
    </v-row>
    <v-data-table
      class="px-1 elevation-3"
      :headers="tableHeaders"
      :items="wrappedRequestsTransfer"
      :items-per-page="10"
      :page.sync="filters.page"
      hide-default-footer
      :loading="loadingRequestsTransfer"
      loading-text="Cargando solicitudes de traslado"
      item-key="id"
      show-expand
      :expanded.sync="expanded"
      no-data-text="Sin solicitudes de traslado"
    >
      <template v-slot:item="{ item, isExpanded }">
        <tr @click="clicked(item)">
          <td>
            <p class="text-left my-0">
              {{ getTextProjectFromKey(item, 0) }}
            </p>
          </td>
          <td>
            <p class="mt-2 my-0 mr-5 pr-2" style="font-size: 12px;">
              <span class="text-success">
                {{
                  getTextProjectFromKey(item, 1) !== "None"
                    ? moment(getTextProjectFromKey(item, 1)).format(
                        "DD/MM/YYYY"
                      )
                    : "-"
                }}
              </span>
            </p>
          </td>
          <td>
            <p class="ml-1 text-left my-0" style="font-size: 11px">
              {{
                getTextProjectFromKey(item, 2) !== "None"
                  ? getTextProjectFromKey(item, 2)
                  : "-"
              }}
            </p>
          </td>
          <td>
            <p class="ml-5 pl-5 my-0" style="font-size: 11px">
              {{ Object.values(item)[0].length }}
            </p>
          </td>
          <td>
            <v-icon>
              {{ isExpanded ? "mdi-chevron-up" : "mdi-chevron-down" }}
            </v-icon>
          </td>
        </tr>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="bg-gray">
          <section class="my-5 px-3">
            <v-simple-table class="elevation-3">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Acción</th>
                    <th class="text-left">Etapa del proyecto</th>
                    <th class="text-left">Código</th>
                    <th class="text-left">Tipo de solicitud</th>
                    <th class="text-left">Bodega Origen</th>
                    <th class="text-left">Bodega Destino</th>
                    <th class="text-left">Fecha de creación</th>
                    <th class="text-center">Estatus</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(request, index) in Object.values(item)[0]"
                    :key="request.id"
                  >
                    <td>
                      <div
                        class="d-flex align-items-center justify-content-start"
                      >
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              @click="
                                openModalMaterialsRequestTransfer(request)
                              "
                              icon
                              color="primary"
                              v-bind="attrs"
                              v-on="on"
                              x-small
                            >
                              <v-icon>
                                mdi-view-list-outline
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Ver materiales de la solicitud</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              @click="openModalDetailsDispatch(request)"
                              icon
                              :disabled="!request.received_at"
                              :color="
                                request.received_at ? 'primary' : 'warning'
                              "
                              v-bind="attrs"
                              v-on="on"
                              x-small
                              class="mx-3"
                            >
                              <v-icon>
                                mdi-truck
                              </v-icon>
                            </v-btn>
                          </template>
                          <span
                            >Ver detalles del despacho
                            {{
                              !request.received_at ? "(Sin Despachar)" : ""
                            }}</span
                          >
                        </v-tooltip>
                      </div>
                    </td>
                    <td>
                      <p class="my-0 text-left">
                        {{ request.stage_code ? request.stage_code : "-" }}
                      </p>
                    </td>
                    <td>
                      <p class="text-left my-0">
                        {{ request.sap_code }}
                      </p>
                    </td>
                    <td>
                      <p class="my-0 text-left">
                        {{ request.request_type.name }}
                      </p>
                    </td>
                    <td>
                      <p class="text-left my-0">
                        {{ request.origin_warehouse }}
                      </p>
                    </td>
                    <td>
                      <p class="text-left my-0">
                        {{ request.destination_warehouse }}
                      </p>
                    </td>
                    <td>
                      <p class="my-0 text-left" style="font-size: 11x;">
                        {{ new Date(request.created_at).toLocaleDateString() }}
                      </p>
                    </td>
                    <td class="px-0">
                      <v-edit-dialog
                        :ref="`edit-dialog-${request.sap_code}-${index}`"
                      >
                        <div
                          class="d-flex align-items-center justify-content-start"
                        >
                          <v-chip
                            label
                            small
                            :color="
                              getMappedStatusRequestTransfer(
                                request.manual_status
                              ).color
                            "
                            class="text-white"
                          >
                            {{
                              getMappedStatusRequestTransfer(
                                request.manual_status
                              ).name
                            }}
                          </v-chip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                @click="openCurrentEditDialog(request, index)"
                                icon
                                class="mx-2"
                                color="warning"
                                x-small
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon small>
                                  mdi-pencil
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Modificar estatus de la solicitud</span>
                          </v-tooltip>
                        </div>
                        <template v-slot:input>
                          <v-select
                            :value="
                              getMappedStatusRequestTransfer(
                                request.manual_status
                              ).key
                            "
                            label="Modificar estatus"
                            single-line
                            :items="
                              Object.entries(MAP_STATUS_TRANSFER_REQUESTS).map(
                                ([value, { name }]) => ({
                                  value,
                                  name,
                                })
                              )
                            "
                            item-text="name"
                            item-value="value"
                            @change="
                              updateStatusTransferRequest(
                                $event,
                                request,
                                item.id
                              );
                              closeCurrentEditDialog(request, index);
                            "
                          ></v-select>
                        </template>
                      </v-edit-dialog>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </section>
        </td>
      </template>
    </v-data-table>
    <div class="my-3 float-right">
      <v-pagination
        total-visible="8"
        v-model="filters.page"
        :length="
          requestsDelivery.number_of_pages
            ? requestsDelivery.number_of_pages
            : 1
        "
      ></v-pagination>
    </div>
    <modal-materials-request-transfer
      :actionDispatch="false"
      :modalAction="dialogMaterialsRequestTransfer"
      :currentRequestTransfer="currentRequestTransfer"
      :closeModalMaterialsRequestTransfer="closeModalMaterialsRequestTransfer"
      @openModalDetailsDispatch="openModalDetailsDispatch"
    >
    </modal-materials-request-transfer>
    <modal-details-dispatch
      :modalAction="dialogDetailsDispatch"
      :closeModalDetailsDispatch="closeModalDetailsDispatch"
      :currentItemDispatch="currentItemDetailsDispatch"
    >
    </modal-details-dispatch>
  </v-container>
</template>

<script>
import swal2 from "sweetalert2";
import moment from "moment";
import FilterProject from "@/components/commons/projects/FilterProject.vue";
import { authUsuarioComputed } from "@/state/helpers";
import { mapActions, mapGetters } from "vuex";
import ModalMaterialsRequestTransfer from "../dateDelivery/ModalMaterialsRequestTransfer.vue";
import ModalDetailsDispatch from "../dateDelivery/ModalDetailsDispatch.vue";
import {
  TYPE_REQUESTS_DISPATCH,
  TYPE_REQUEST_TRANSFER,
  TYPE_REQUEST_RETREAT,
  CODE_WAREHOUSE_CENTRAL_FLUXSOLAR,
} from "@/constants/requestMaterial";
import {
  MAP_STATUS_TRANSFER_REQUESTS,
  MAP_STATUS_DISPATCH_TRANSFER_REQUEST,
} from "@/constants/supplying/transferRequest";

export default {
  components: {
    FilterProject,
    ModalMaterialsRequestTransfer,
    ModalDetailsDispatch,
  },
  mounted() {
    this.getRequestsDeliveryData();
    this.getWarehousesData();
  },
  beforeDestroy() {
    this.clearDeliveryRequests();
  },
  data() {
    return {
      moment,
      MAP_STATUS_TRANSFER_REQUESTS,
      MAP_STATUS_DISPATCH_TRANSFER_REQUEST,
      TYPE_REQUESTS_DISPATCH,
      TYPE_REQUEST_TRANSFER,
      TYPE_REQUEST_RETREAT,
      breadcrumb: [
        {
          text: "Proyectos",
          disabled: false,
          href: "/",
        },
        {
          text: "Solicitud de Traslado",
          disabled: true,
          href: "/supplying/cd/requests-transfer",
        },
      ],
      expanded: [],
      datepicker_menu_start: false,
      datepicker_menu_end: false,
      filters: {
        filter: "",
        page: 1,
        date_start: null,
        date_end: null,
        received_type: null,
        date_dispatch: false,
        dispatch_status: null,
        warehouse: CODE_WAREHOUSE_CENTRAL_FLUXSOLAR,
      },
      currentProject: null,
      dialogMaterialsRequestTransfer: false,
      dialogDetailsDispatch: false,
      loadingRequestsTransfer: false,
      debounceWarehouses: null,
      debounceRequests: false,
      debounceRequestsTime: 500,
      currentRequestTransfer: null,
      currentItemDetailsDispatch: null,
      tableHeaders: [
        {
          text: "Projecto",
          align: "start",
          sortable: false,
          value: "project",
          width: "10%",
        },
        {
          text: "Fecha de entrega",
          align: "center",
          sortable: false,
          value: "received_at",
        },
        {
          text: "Tipo de Despacho",
          align: "start",
          sortable: false,
          value: "received_type",
          width: "10%",
        },
        {
          text: "Cantidad",
          align: "center",
          sortable: false,
          value: "quantity",
        },
        { text: "Solicitudes", align: "center", value: "data-table-expand" },
      ],
    };
  },
  methods: {
    ...mapActions({
      getProjects: "requestMaterialsSap/getProjects",
      getWarehouses: "requestMaterialsSap/getWarehouses",
      getRequestsDelivery: "supplying/getRequestsDelivery",
      clearDeliveryRequests: "supplying/clearDeliveryRequests",
      sendUpdateStatusTransferRequest: "supplying/updateStatusTransferRequest",
    }),
    clicked(value) {
      if (this.expanded.find((item) => item.id == value.id)) {
        return (this.expanded = this.expanded.filter(
          (item) => item.id !== value.id
        ));
      }
      this.expanded.push(value);
    },
    getTextProjectFromKey(itemRequest, position) {
      return itemRequest.id.split("_")[position];
    },
    clearDateFilters() {
      this.filters.date_start = null;
      this.filters.date_end = null;
      this.getRequestsDeliveryData(this.filters.page);
    },
    getMappedStatusRequestTransfer(requestTransferStatus) {
      return !requestTransferStatus
        ? MAP_STATUS_TRANSFER_REQUESTS.transfer_request
        : MAP_STATUS_TRANSFER_REQUESTS[requestTransferStatus];
    },
    async getWarehousesData(search = null) {
      clearTimeout(this.debounceWarehouses);
      let self = this;
      this.debounceWarehouses = setTimeout(async function() {
        await self.getWarehouses({
          filter: search ? search : undefined,
          items: 30,
        });
      }, self.debounceTime);
    },
    async getRequestsDeliveryDataAfterValidateDates() {
      if (this.filters.date_start && this.filters.date_end) {
        await this.getRequestsDeliveryData(this.filters.page);
      }
    },
    updateCurrentProject(project_code) {
      this.currentProject = project_code;
    },
    cleanCurrentProject() {
      this.currentProject = null;
    },
    async getRequestsDeliveryData(page = 1) {
      clearTimeout(this.debounceRequests);
      this.debounceRequests = setTimeout(async () => {
        this.loadingRequestsTransfer = true;
        await this.getRequestsDelivery({
          page: page,
          filter: this.filters.filter ? this.filters.filter : undefined,
          warehouse_filter: this.filters.warehouse
            ? this.filters.warehouse
            : undefined,
          project_filter: this.currentProject
            ? this.currentProject.split(" - ")[0]
            : undefined,
          grouping_filter: true,
          status_filter:
            this.filters.dispatch_status == null
              ? undefined
              : this.filters.dispatch_status,
          with_date: this.filters.date_dispatch ? false : true,
          since:
            this.filters.date_start && this.filters.date_end
              ? `${this.filters.date_start} 00:00:00`
              : undefined,
          until:
            this.filters.date_start && this.filters.date_end
              ? `${this.filters.date_end} 23:59:59`
              : undefined,
          dispatch_type: this.filters.received_type
            ? this.filters.received_type
            : undefined,
        });
        this.loadingRequestsTransfer = false;
      }, this.debounceRequestsTime);
    },
    async updateStatusTransferRequest(newStatus, request, id_group) {
      this.loading = true;
      let payload = {
        id: request.id,
        id_group,
        status: MAP_STATUS_TRANSFER_REQUESTS[newStatus].key,
        sap_code: request.sap_code,
        user: this.user.email,
      };
      const resp = await this.sendUpdateStatusTransferRequest(payload);
      if (!resp.status || resp.status === 400) {
        this.loading = false;
        return swal2.fire({
          icon: "error",
          title: "Error",
          text: resp.data.detail,
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 10000,
          timerProgressBar: true,
        });
      }
      if (resp.status == 200) {
        swal2.fire({
          icon: "success",
          title: "Excelente",
          text: "Estatus de solicitud actualizado correctamente",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 5000,
          timerProgressBar: true,
        });
      }
      this.loading = false;
    },
    closeCurrentEditDialog(item, index) {
      this.$refs[`edit-dialog-${item.sap_code}-${index}`].isActive = false;
    },
    openCurrentEditDialog(item, index) {
      this.$refs[`edit-dialog-${item.sap_code}-${index}`].isActive = true;
    },
    openModalMaterialsRequestTransfer(requestTransfer) {
      this.currentRequestTransfer = requestTransfer;
      this.dialogMaterialsRequestTransfer = true;
    },
    closeModalMaterialsRequestTransfer() {
      this.currentRequestTransfer = null;
      this.dialogMaterialsRequestTransfer = false;
    },
    openModalDetailsDispatch(itemDispatch) {
      this.currentItemDetailsDispatch = itemDispatch;
      this.dialogDetailsDispatch = true;
    },
    closeModalDetailsDispatch() {
      this.currentItemDetailsDispatch = null;
      this.dialogDetailsDispatch = false;
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      projects: "requestMaterialsSap/projects",
      requestsDelivery: "supplying/requestsDelivery",
      warehouses: "requestMaterialsSap/warehouses",
    }),
    wrappedRequestsTransfer() {
      return this.requestsDelivery.items;
    },
    date_start_formatted() {
      if (this.filters.date_start) {
        return moment(this.filters.date_start).format("DD/MM/YYYY");
      }
      return null;
    },
    date_end_formatted() {
      if (this.filters.date_end) {
        return moment(this.filters.date_end).format("DD/MM/YYYY");
      }
      return null;
    },
    minDateEnd() {
      if (this.filters.date_start) {
        return this.filters.date_start;
      }
      return null;
    },
    maxDateStart() {
      if (this.filters.date_end) {
        return this.filters.date_end;
      }
      return null;
    },
  },
  watch: {
    "filters.page": function(page) {
      this.getRequestsDeliveryData(page);
    },
  },
};
</script>

<style></style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-dialog',{attrs:{"max-width":"1200px","scrollable":""},on:{"keydown":_vm.executeCloseModalMaterialsRequestTransfer,"click:outside":_vm.executeCloseModalMaterialsRequestTransfer},model:{value:(_vm.modalAction),callback:function ($$v) {_vm.modalAction=$$v},expression:"modalAction"}},[(_vm.currentRequestTransfer)?_c('v-card',[_c('div',{staticClass:"p-4 pb-0"},[_c('h5',[_vm._v(" COD Solicitud: "),_c('b',[_vm._v(" "+_vm._s(_vm.currentRequestTransfer.sap_code)+" ")]),_vm._v(" | Tipo de solicitud: "),_c('b',[_vm._v(" "+_vm._s(_vm.currentRequestTransfer.request_type.name)+" ")])])]),_c('hr'),_c('v-card-text',[_c('v-container',{staticClass:"pt-0"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('section',{staticClass:"w-25"},[_c('v-text-field',{staticClass:"px-2",attrs:{"clearable":"","placeholder":"Buscar:"},model:{value:(_vm.filters.filter),callback:function ($$v) {_vm.$set(_vm.filters, "filter", $$v)},expression:"filters.filter"}})],1),(_vm.actionDispatch)?_c('h5',{staticClass:"mt-3 mx-3"},[_vm._v(" Seleccionados: "+_vm._s(_vm.materialsSelected.length)+" ")]):_vm._e()]),(_vm.actionDispatch)?_c('div',{staticClass:"d-flex justify-content-end mb-4"},[_c('v-btn',{attrs:{"disabled":!_vm.materialsSelected.length,"color":"primary text-white","small":""},on:{"click":_vm.openModalDeliveryMaterials}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-truck-outline ")]),_vm._v(" Despachar materiales ")],1)],1):_vm._e(),_c('v-data-table',{staticClass:"px-2 elevation-4 pt-2",attrs:{"headers":_vm.tableHeaders,"items":_vm.filteredMaterials,"items-per-page":200,"hide-default-footer":"","loading-text":"Cargando materiales","item-key":"id","no-data-text":"Sin materiales","show-select":_vm.actionDispatch},on:{"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-start"},[_c('v-chip',{staticClass:"mt-0 text-white",attrs:{"label":"","small":"","color":"primary"}},[_vm._v(" "+_vm._s(item.material.code)+" ")])],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-left my-0",staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(item.material.name)+" ")])]}},{key:"item.received_type",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-center my-0",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(item.received_type ? item.received_type : _vm.currentRequestTransfer.received_type ? _vm.currentRequestTransfer.received_type : "-")+" ")])]}},{key:"item.unit",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-center my-0",staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(item.material.measurement_unit)+" ")])]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-right mt-4",staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(item.quantity)+" ")])]}},{key:"item.received_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"mt-2",staticStyle:{"font-size":"12px"}},[(item.received_at)?_c('span',{staticClass:"text-success"},[_vm._v(" "+_vm._s(_vm.moment(item.received_at).format("DD/MM/YYYY HH:mm [hrs]"))+" ")]):(_vm.currentRequestTransfer.received_at)?_c('span',{staticClass:"text-success"},[_vm._v(" "+_vm._s(_vm.moment(_vm.currentRequestTransfer.received_at).format( "DD/MM/YYYY HH:mm [hrs]" ))+" ")]):_c('v-chip',{attrs:{"color":"warning","small":""}},[_c('small',[_vm._v(" Sin Definir ")])])],1)]}},{key:"item.expiry_date",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"mt-2",staticStyle:{"font-size":"12px"}},[(item.expiry_date)?_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.moment(item.expiry_date).format("DD/MM/YYYY HH:mm [hrs]"))+" ")]):(_vm.currentRequestTransfer.expiry_date)?_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.moment(_vm.currentRequestTransfer.expiry_date).format( "DD/MM/YYYY HH:mm [hrs]" ))+" ")]):_c('v-chip',{attrs:{"color":"warning","small":""}},[_c('small',[_vm._v(" Sin Definir ")])])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-3",attrs:{"icon":"","disabled":!item.received_at &&
                          !_vm.currentRequestTransfer.received_at,"color":item.received_at || _vm.currentRequestTransfer.received_at
                          ? 'primary'
                          : 'warning',"x-small":""},on:{"click":function($event){return _vm.openModalDetailsDispatch(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-truck ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver detalles del despacho "+_vm._s(!item.received_at && !_vm.currentRequestTransfer.received_at ? "(Sin Despachar)" : ""))])])],1)]}}],null,false,2984011381),model:{value:(_vm.materialsSelected),callback:function ($$v) {_vm.materialsSelected=$$v},expression:"materialsSelected"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","outlined":""},on:{"click":_vm.executeCloseModalMaterialsRequestTransfer}},[_c('small',[_vm._v(" Cerrar ")])])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }